import React from 'react';

import { Header, MediaAssets, MediaEditor } from './HomeComponents';
import './HomeStyles/homeStyles.scss';

const Home = () => {
  return (
    <div className="home-container">
      <Header />
      <div className="home-components-container">
        <MediaEditor />
        <MediaAssets />
      </div>
    </div>
  );
};

export default Home;
