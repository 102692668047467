import './App.scss';

import { SignIn, Home } from './pages';
import { useAuth } from './context/AuthContext';
import { AppStateProvider } from './context/AppStateContext';

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div className={`${isAuthenticated ? 'app-authorized' : 'app-unauthorized'}`}>
      {isAuthenticated ? (
        <AppStateProvider>
          <Home />
        </AppStateProvider>
      ) : (
        <SignIn />
      )}
    </div>
  );
};

export default App;
