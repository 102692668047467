import React from 'react';

import { TriangleRightIcon, HorizontalLineIcon } from '../../../../assets';
import '../../HomeStyles/timeLineMarkerStyles.scss';

const TimelineMarker = () => {
  return (
    <div className="playback-marker-container">
      <div className="triangle-icon-container">
        <TriangleRightIcon />
      </div>
      <div className="line-icon-container">
        <HorizontalLineIcon />
      </div>
    </div>
  );
};

export default TimelineMarker;
