import React, { useEffect } from 'react';
import interact from 'interactjs';

import { Timeline, VideoLayer, TimelineMarker } from './MediaEditorComponents';
import { BackArrowIcon } from '../../../assets';
import '../HomeStyles/mediaEditorStyles.scss';
import { useAppState } from '../../../context/AppStateContext';
const MediaEditor = () => {
  const { setDroppedVideo, timeScale, editingVideos } = useAppState();

  useEffect(() => {
    interact('.video').draggable({
      listeners: {
        move: function (event) {
          const target = event.target;
          const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
          const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

          target.style.transform = `translate(${x}px, ${y}px)`;
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);

          const layersContainer = document.querySelector('.video-editor-functions');
          const layersRect = layersContainer.getBoundingClientRect();
          const targetRect = target.getBoundingClientRect();

          if (
            targetRect.left >= layersRect.left &&
            targetRect.right <= layersRect.right &&
            targetRect.top >= layersRect.top &&
            targetRect.bottom <= layersRect.bottom
          ) {
            layersContainer.style.opacity = 0.7;
          } else {
            layersContainer.style.opacity = 1;
          }
        },
        end: function (event) {
          const layersContainer = document.querySelector('.video-editor-functions');
          layersContainer.style.opacity = 1;

          const target = event.target;
          target.style.transition = 'transform 0.4s ease';
          target.style.transform = '';
          target.setAttribute('data-x', '0');
          target.setAttribute('data-y', '0');
          const targetRect = target.getBoundingClientRect();
          const layersRect = layersContainer.getBoundingClientRect();

          setTimeout(() => {
            target.style.transition = '';
          }, 500);

          if (
            targetRect.left >= layersRect.left &&
            targetRect.right <= layersRect.right &&
            targetRect.top >= layersRect.top &&
            targetRect.bottom <= layersRect.bottom
          ) {
            const videoFilename = target.querySelector('span').textContent;
            setDroppedVideo(videoFilename);
          }
        },
      },
    });
  }, []);
  return (
    <div className="video-editor-container">
      <div className="video-editor-components">
        <div className="video-editor-components-header">
          <span>Layer 1</span>
        </div>
        <div className="video-editor-functions">
          {<Timeline endTime={timeScale} />}
          <TimelineMarker />
          <div className="video-editor-layers-container">
            {editingVideos.map((video, index) => (
              <VideoLayer key={index} video={video} />
            ))}
            <input placeholder="What's next?"></input>
          </div>
        </div>
      </div>
      <div className="audio-editor-components">
        <button className="hide-info-button">
          <div className="back-arrow-icon">
            <BackArrowIcon />
          </div>
        </button>
        <div className="audio-editor-layers-container">
          <div className="audio-editor-layer"></div>
          <div className="audio-editor-layer"></div>
        </div>
      </div>
    </div>
  );
};

export default MediaEditor;
