import React, { memo, useMemo, useCallback } from 'react';
import '../../HomeStyles/timeLine.scss';
import { DotIcon } from '../../../../assets';
import { getTimeforTimescale } from '../../../../helpers/mediaHelpers';

const Timeline = ({ endTime }) => {
  const startTime = 0;
  const step = 1;

  const generateTimelineItems = useCallback(() => {
    const items = [];

    for (let time = startTime; time <= endTime; time += step) {
      const seconds = getTimeforTimescale(time);
      items.push(
        <div
          key={time}
          className={`timeline-item ${
            seconds.endsWith('0') || seconds.endsWith('5') ? 'bold' : ''
          }`}
        >
          {seconds === '0.00' ? (
            <span>{seconds}</span>
          ) : (
            <div className="timeline-seconds">
              <DotIcon className="dot" />
              <DotIcon className="dot" />
              <DotIcon className="dot" />
              <DotIcon className="dot" />
              <span>{seconds}</span>
            </div>
          )}
        </div>,
      );
    }

    return items;
  }, [startTime, endTime, step]);

  const timelineItems = useMemo(() => generateTimelineItems(), [generateTimelineItems]);

  return <div className="timeline">{timelineItems}</div>;
};

export default memo(Timeline);
