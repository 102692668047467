import React from 'react';

import '../HomeStyles/mediaAssetsStyles.scss';
import { useAppState } from '../../../context/AppStateContext';
import { BackArrowIcon, FolderImage } from '../../../assets';

const REACT_APP_S3MEDIA_URL = process.env.REACT_APP_S3MEDIA_URL;

const MediaAssets = () => {
  const {
    mediaGroupData,
    mediaGroupID,
    setMediaGroupID,
    mediaByGroupIDData,
    setMediaByGroupIDData,
  } = useAppState();

  const handleMediaGroupID = (projectId) => {
    setMediaGroupID(projectId);
  };

  const clearMediaGroupID = () => {
    setMediaGroupID(null);
    setMediaByGroupIDData(null);
  };

  const renderMediaGroups = () => {
    if (mediaGroupData) {
      return Object.entries(mediaGroupData).map(([projectId, projectData]) => (
        <div
          className="media-assets-groups"
          key={projectId}
          onClick={() => handleMediaGroupID(projectId)}
        >
          <img src={FolderImage} alt="folder" />
          <span>{projectData?.project_name}</span>

          {/*<span>{projectData?.project_description}</span>*/}
        </div>
      ));
    }
  };

  const renderMediaByGroupID = () => {
    if (mediaByGroupIDData) {
      return Object.entries(mediaByGroupIDData).map(([projectId, projectData]) => (
        <div className="video" data-x="0" data-y="0" key={projectId}>
          <video src={`${REACT_APP_S3MEDIA_URL}/${projectData.file_id}`} />
          <span>{projectId}</span>
        </div>
      ));
    } else return <span className="no-media-text">No media found</span>;
  };

  return (
    <div className="media-assets-container">
      <div className="media-assets-header">
        <div className="media-assets-title">
          <span>Project Assets (stored in S3 bucket)</span>
          {/*mediaGroupID && <span>Media Group: {}</span>*/}
        </div>
        {mediaGroupID && (
          <button className="back-button" onClick={() => clearMediaGroupID()}>
            <div className="back-button-icon">
              <BackArrowIcon />
            </div>
          </button>
        )}
      </div>
      <div className="media-folders">
        <div className="assets-container">
          {mediaGroupID ? renderMediaByGroupID() : renderMediaGroups()}
        </div>
      </div>
    </div>
  );
};

export default MediaAssets;
