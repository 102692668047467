import React from 'react';
import '../HomeStyles/headerStyles.scss';
import { MoonshineLogo } from '../../../assets';

const Header = () => {
  return (
    <div className="header-container">
      <div className="header-row-container">
        <div className="timeline-container">
          <img src={MoonshineLogo} alt="MoonshineLogo" />
          <span>Timeline Component</span>
        </div>
        <div className="component-name">
          <span>component-AAT2</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
