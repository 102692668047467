import React, { createContext, useContext, useEffect, useState } from 'react';

import { useAuth } from './AuthContext';
import { getMediaGroup, getMediaByGroupID, getVideoDuration } from '../api/mediaAPI';

const AppStateContext = createContext();

export const AppStateProvider = ({ children }) => {
  const { isAuthenticated, userID } = useAuth();
  const [mediaGroupData, setMediaGroupData] = useState(null);
  const [mediaGroupID, setMediaGroupID] = useState(null);
  const [mediaByGroupIDData, setMediaByGroupIDData] = useState(null);
  const [editingVideos, setEditingVideos] = useState([]);
  const [droppedVideo, setDroppedVideo] = useState(null);
  const initialTimeScaleValue = 21;
  const [timeScale, setTimeScale] = useState(initialTimeScaleValue);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchData = async () => {
        try {
          const data = await getMediaGroup(userID);
          setMediaGroupData(data);
        } catch (error) {
          console.log('Error fetching media group:', error);
        }
      };
      fetchData();
    }
  }, [userID, isAuthenticated]);

  useEffect(() => {
    if (mediaGroupID) {
      const fetchData = async () => {
        try {
          const data = await getMediaByGroupID(userID, mediaGroupID);
          setMediaByGroupIDData(data);
        } catch (error) {
          console.log('Error fetching media group:', error);
        }
      };
      fetchData();
    }
  }, [mediaGroupID, userID]);

  useEffect(() => {
    const fetchData = async () => {
      if (!droppedVideo || !mediaByGroupIDData) {
        return;
      }

      const droppedVideoInfo = mediaByGroupIDData[droppedVideo];
      if (droppedVideoInfo) {
        const { file_id, metadata, upload_time } = droppedVideoInfo;
        const { fps } = metadata;

        try {
          const duration = await getVideoDuration(file_id);
          const newEditingVideo = {
            file_id,
            file_name: droppedVideo,
            fps,
            uploadTime: upload_time,
            file_url: `https://moonshine-media.s3.amazonaws.com/${file_id}`,
            video_duration: Math.round(duration),
          };

          setEditingVideos((prevEditingVideos) => [...prevEditingVideos, newEditingVideo]);
        } catch (error) {
          console.error(error);
          // Handle the error as needed
        }
      }
    };

    fetchData(); // Call the async function
    setDroppedVideo(null);
  }, [droppedVideo]);

  useEffect(() => {
    const videoDurations = editingVideos.map((video) => video.video_duration);
    const totalDuration = videoDurations.reduce(
      (accumulator, duration) => accumulator + duration,
      0,
    );
    setTimeScale(totalDuration + initialTimeScaleValue);
  }, [editingVideos]);

  return (
    <AppStateContext.Provider
      value={{
        mediaGroupData,
        mediaGroupID,
        setMediaGroupID,
        mediaByGroupIDData,
        setMediaByGroupIDData,
        setDroppedVideo,
        editingVideos,
        timeScale,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => {
  return useContext(AppStateContext);
};
