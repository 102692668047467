import axios from 'axios';
import { config } from './headersConfig';

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const REACT_APP_S3MEDIA_URL = process.env.REACT_APP_S3MEDIA_URL;

export const getMediaGroup = async (user_ID) => {
  try {
    const mediaGroup = await axios.get(`${REACT_APP_BASE_URL}/media-groups?userid=${user_ID}`, {
      headers: {
        ...config.headers,
      },
    });
    return mediaGroup.data;
  } catch (error) {
    console.log('getMediaGroup error:', error);
  }
};

export const getMediaByGroupID = async (user_ID, mediaGroupID) => {
  try {
    const mediaGroupByID = await axios.get(
      `${REACT_APP_BASE_URL}/media-resources?userid=${user_ID}&projectid=${mediaGroupID}`,
      {
        headers: {
          ...config.headers,
        },
      },
    );
    return mediaGroupByID.data;
  } catch (error) {
    console.log('getMediaByGroupID error:', error);
  }
};

export const getVideoDuration = async (fileId) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');

    const handleLoadedMetadata = () => {
      resolve(video.duration);
    };

    video.addEventListener('loadedmetadata', handleLoadedMetadata);
    video.src = `${REACT_APP_S3MEDIA_URL}/${fileId}`;

    const cleanup = () => {
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };

    video.addEventListener('error', () => {
      cleanup();
      reject(new Error('Error loading video metadata.'));
    });

    setTimeout(() => {
      cleanup();
      reject(new Error('Timeout while loading video metadata.'));
    }, 5000);
  });
};
