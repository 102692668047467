import React, { useState } from 'react';
import './Styles/signin.scss';
import { useAuth } from '../../context/AuthContext';
import { MoonshineLogo } from '../../assets';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { setIsAuthenticated, setUserID } = useAuth();

  const envUsername = process.env.REACT_APP_USERNAME;
  const envPassword = process.env.REACT_APP_PASSWORD;

  const handleSignIn = () => {
    if (password === envPassword && email === envUsername) {
      setIsAuthenticated(true);
      setUserID('64b89448-5051-7031-7c50-9fc46a3ae188');
    } else {
      setErrorMessage('Invalid credentials');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  return (
    <div className="sign-in-container">
      <div className="sign-in-header-container">
        <div className="logo-container">
          <img src={MoonshineLogo} alt="MoonshineLogo" />
          <span>Moonshine</span>
        </div>
      </div>
      <div className="form-container">
        <h4>Sign In</h4>
        <form>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="button" onClick={handleSignIn}>
            Sign In
          </button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default SignIn;
