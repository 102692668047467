import React from 'react';
import '../../HomeStyles/videoLayerStyles.scss';
import { getTimeforVideo } from '../../../../helpers/mediaHelpers';

const VideoLayer = ({ video, index }) => {
  const videoDuration = getTimeforVideo(video.video_duration);
  return (
    <div className="video-layer" key={index}>
      <div className="video-screens">
        {[...Array(video.video_duration)].map((_, screenIndex) => {
          const imageUrl = `https://moonshine-media-thumbnails.s3.amazonaws.com/${video.file_id}/${
            screenIndex * 30
          }.jpg`;

          return (
            <div className="video-screen" key={screenIndex}>
              <img src={imageUrl} alt={`thumbnail ${screenIndex}`} />
            </div>
          );
        })}
      </div>
      <div className="video-layer-info">
        <div className="video-layer-header">
          <div className="video-layer-icon"></div>
          <span className="video-layer-file-name">{video.file_name}</span>
          <span className="video-duration">0:00 - {videoDuration}</span>
        </div>
        <span className="video-layer-description"></span>
      </div>
    </div>
  );
};

export default VideoLayer;
